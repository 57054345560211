import React, { useState } from "react";
import loadable from "@loadable/component";
import usePostHook from "../../customHooks/usePostHook";
import APIS from "../../contants/EndPoints";
import { useParams } from "react-router-dom";

const OrdersComponent = loadable(() => import("../../components/Orders/Orders"));

const Orders = () => {
  const [selectedValue, setSelectedValue] = useState()
  const { isPostLoading: isValueLoading, mutateAsync: statusMutateAsync, postData: data } = usePostHook({ queryKey: ["order-received"], navigateURL: -1 })
  const handleStatusOrderReceived = async (values: any) => {
    const url = `${APIS.ORDERS}${selectedValue}/response-received/`

    try {
      const res = await statusMutateAsync({ url })
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <>
      <OrdersComponent
        setSelectedValue={setSelectedValue}
        handleStatusOrderReceived={handleStatusOrderReceived}
      />
    </>
  );
};

export default Orders;
