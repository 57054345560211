import React from "react";
import loadable from "@loadable/component";
import { useParams } from "react-router-dom";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../contants/EndPoints";

const RawMaterialsHistoryComponent = loadable(() => import("../../components/RawMaterials/RawMaterialsHistory"));

const RawMaterialsHistory = () => {
  const{id}=useParams()
  const { data: singleOrderData, isLoading } = useGetHook({
    queryKey: [`vieworder`],
    url: `${APIS.ORDERS}${id}`,
    params: {
    }
  });
  console.log(singleOrderData,"single");
    return (
    <>
      <RawMaterialsHistoryComponent
      singleOrderData={singleOrderData?singleOrderData:null}
      />
    </>
  );
};

export default RawMaterialsHistory;
