import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "../pages/ErrorPage/PageNotFound";
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import Product from "../pages/Product/Product";
import Orders from "../pages/Orders/Orders";
import OrderReceived from "../pages/OrderReceived/OrderReceived";
import RawMaterials from "../pages/RawMaterials/RawMaterials";
import ViewOrders from "../pages/Orders/ViewOrders";
import CreateMail from "../pages/Orders/CreateMail";
import ProductHistory from "../pages/Product/ProductHistory";
import RawMaterialsHistory from "../pages/RawMaterials/RawMaterialsHistory";
import OrderReceivedDetails from "../pages/OrderReceived/OrderReceivedDetails";
import OrderReceivedConfirm from "../pages/OrderReceived/OrderReceivedConfirm";

// import AccessControl from "../helpers/accessControl";
// import PermissionDenied from "../pages/Error/PermissionDenied";

// https://adarshaacharya.com.np/blog/role-based-auth-with-react-router-v6

/**
 * Top level application router
 *
 * @returns {JSX.Element}
 */
const Router = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        <Route path={"/"} element={<Dashboard />} />
        <Route path={"/products"} element={<Product />} />
        <Route path={"/orders"} element={<Orders />} />
        <Route path={"/view-orders/:id"} element={<ViewOrders />} />
        <Route path={"/create-mail/:id"} element={<CreateMail />} />
        <Route path={"/order-received"} element={<OrderReceived />} />
        <Route path={"/view-order-received/:id"} element={<OrderReceivedDetails />} />
        <Route path={"/edit-order-received/:id"} element={<OrderReceivedConfirm />} />
        <Route path={"/history/:id"} element={<ProductHistory />} />
        <Route path={"/history/:id"} element={<RawMaterialsHistory />} />
        <Route path={"/raw-material"} element={<RawMaterials />} />
        <Route path="/*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;
