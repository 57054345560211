import { useEffect, useState } from "react";
import loadable from "@loadable/component";
import { PRODUCTS, SUPPLIERS } from "./queries";
import { useQuery } from "@apollo/client";
import usePostHook from "../../customHooks/usePostHook";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";

const ProductComponent = loadable(() => import("../../components/Product/Product"));

const Product = () => {

  const { data: supplierData, loading: supplierloaging, error: supplierError } = useQuery(SUPPLIERS);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null)
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const [search, setSearch] = useState<string>('');
  const { data, loading, error } = useQuery(PRODUCTS, {
    variables: {
      first: itemsPerPage,
      offset: currentPage,
      search: search,
    },

  });
  useEffect(() => {
    console.log(selectedValue)
  }, [selectedValue])

  const { isPostLoading, mutateAsync, postData } = usePostHook({ queryKey: ["product"], navigateURL: null })
  const handleCreatePurchase = async (values: any, selectedValue: string) => {
    const url = `${APIS.ORDERS}product-order/`
    const formData = {
      supplier: values?.supplier,
      product: selectedValue,
      cost_price: values?.cost_price,
      quantity: values?.quantity,
      discount: values?.discount,
      gst: values?.gst,
      discount_type: "percentage"
    }
    try {
      const res = await mutateAsync({ url, formData })
      console.log(res);
      setIsOpen(false)
    } catch (err) {
      console.log(err);
    }
  };

  
  if (error) return <>Error...</>
  return (
    <>
      <ProductComponent
        productData={data?.products}
        supplierData={supplierData?.suppliers}
        search={search}
        setSearch={setSearch}
        setItemsPerPage={setItemsPerPage}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        handleCreatePurchase={handleCreatePurchase}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        // singleOrderPurchaseData={singleOrderPurchaseData}
      />

    </>
  );
};

export default Product;
