import React from "react";
import loadable from "@loadable/component";
import { useParams } from "react-router-dom";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../contants/EndPoints";

const ProductHistoryComponent = loadable(() => import("../../components/Product/ProductHistory"));

const ProductHistory = () => {
const {id}=useParams()
const { data: singleOrderData, isLoading } = useGetHook({
  queryKey: [`vieworder`],
  url: `${APIS.ORDERS_ITEMS}`,
  params: {
    product:id
  }
});

  return (
    <>
      <ProductHistoryComponent
      singleOrderData={singleOrderData?singleOrderData:null}
      />
    </>
  );
};

export default ProductHistory;
