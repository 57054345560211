import "./app.css"

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "./routers";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import apolloClient from "./apolloClient";
import { ApolloProvider } from "@apollo/client";


const queryClient = new QueryClient();

export default function Root() {
  return (
    <>
      <ApolloProvider client={apolloClient}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter basename={"/inventory"}>
            <Router />
          </BrowserRouter>
          <ToastContainer />
        </QueryClientProvider>
      </ApolloProvider>
    </>
  );
}
