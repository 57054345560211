import { gql } from "@apollo/client";

export const SUPPLIERS = gql`
  query {
    suppliers {
      edges {
        node {
          id
          pk
          user {
            fullName
          }
        }
      }
    }
  }
`;

// ($search:String!)
//(search : $search)

export const PRODUCTS = gql`
  query product(
    $search: String!
    $first: Int
    $offset: Int
  ) {
    products(
      search: $search
      first: $first
      offset: $offset
    ) {
      edges {
        node {
          id
          pk
          title
   
        }
      }
    }
  }
`;
