import { gql } from "@apollo/client";

export const RAWMATERIALS = gql`
  query rawMaterials($search: String!, $first: Int, $offset: Int) {
    products(search: $search, first: $first, offset: $offset) {
      edges {
        node {
          id
          pk
          title
        }
      }
    }
  }
`;
export const SUPPLIERS = gql`
  query {
    suppliers {
      edges {
        node {
          id
          pk
          user {
            fullName
          }
        }
      }
    }
  }
`;
