import React from "react";
import loadable from "@loadable/component";
import { useParams } from "react-router-dom";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../contants/EndPoints";
import usePostHook from "../../customHooks/usePostHook";

const OrderReceivedConfirmComponent = loadable(() => import("../../components/OrderReceived/OrderReceivedConfirm"));

const OrderReceivedConfirm = () => {
  const { id } = useParams()
  const { data: singleOrderData, isLoading } = useGetHook({
    queryKey: [`vieworder`],
    url: `${APIS.ORDERS}${id}`,
    params: {
    }
  });

  const { isPostLoading, mutateAsync, postData } = usePostHook({ queryKey: ["order-received"], navigateURL: -1 })
  const handleUpdateOrder = async (values: any, selectedValue: string) => {
    const url = `${APIS.ORDERS}update-order/`
    const formData = 
      values
  
    try {
      const res = await mutateAsync({ url, formData })
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
 

  return (
    <>
      <OrderReceivedConfirmComponent
        singleOrderData={singleOrderData}
        handleUpdateOrder={handleUpdateOrder}
      />
    </>
  );
};

export default OrderReceivedConfirm;
