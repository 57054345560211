import React from "react";
import loadable from "@loadable/component";
import usePostHook from "../../customHooks/usePostHook";
import APIS from "../../contants/EndPoints";
import { useParams } from "react-router-dom";
import useGetHook from "../../customHooks/useGetHook";

const CreateMailComponent = loadable(() => import("../../components/Orders/CreateMail"));

const CreateMail = () => {
  const { id } = useParams()
  const { isPostLoading, mutateAsync, postData } = usePostHook({ queryKey: ["mail-create"], navigateURL: "/orders" })
  const handleCreateMail = async (values: any, emailFieldValue: string, Cc: string,notesValue:any) => {
    const url = `${APIS.ORDERS}${id}/send-order/`
    const formData = {
      from_field: "diberptest@dibtech.com.au",
      cc_bcc: values?.Cc,
      to_field: values?.To,
      subject: values?.Subject,
      type: Cc,
      content: emailFieldValue,
      note:notesValue
    }
    try {
      const res = await mutateAsync({ url, formData })
      console.log(res);
    } catch (err) {
      console.log(err);
    }
 


  };


  const { data: singleOrderDataMail, isLoading } = useGetHook({
    queryKey: [`vieworder`],
    url: `${APIS.ORDERS}${id}`,
    params: {
    }
  });

  return (
    <>
      <CreateMailComponent
        handleCreateMail={handleCreateMail}
        singleOrderDataMail={singleOrderDataMail}
      />
    </>
  );
};

export default CreateMail;
