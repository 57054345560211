const APIS = {
  // Pages

  ORDERS: '/orders/',
  ORDERS_ITEMS: '/orders-items/',

  //THEMES
  THEMES: `/theme/`,
  CHANGE_THEME: `/change-theme/`,
  THEME_COMPONENTS: `/theme-templates/`,
};

export default APIS;
