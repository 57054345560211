import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import { useMutation, useQuery } from "@apollo/client";
import { RAWMATERIALS, SUPPLIERS } from "./queries";
import { CREATE_MATERIAL } from "./mutation";
import { toast } from "react-toastify";
import usePostHook from "../../customHooks/usePostHook";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";

const RawMaterialsComponent = loadable(() => import("../../components/RawMaterials/RawMaterials"));


const RawMaterials = () => {
  const [isAddMaterialsOpen, setIsAddMaterialsOpen] = useState(false)
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const [selectedValue, setSelectedValue] = useState("")

  const [search, setSearch] = useState<string>('');
  const { data: supplierData, loading: supplierloading, error: supplierError } = useQuery(SUPPLIERS);

  const { data: rawMaterialsData, loading } = useQuery(RAWMATERIALS,
    {
      variables: {
        first: itemsPerPage,
        offset: page,
        search: search,
      },

    });
  const [createProductPrintOrder] = useMutation(CREATE_MATERIAL, {
    refetchQueries: ["rawMaterials"]
  });
  const handleRawMaterialsAdd = async (data: any) => {
    const response = await createProductPrintOrder({
      variables: {
        title: data?.title,
        threshold: parseFloat(data?.threshold),
        price: data?.cost_price,
        gst:data?.gst
      }
    })
    if (response?.data?.createMaterial?.success) {
      toast.success(response?.data?.createMaterial?.message)
      setIsAddMaterialsOpen(false)
    } else {
      toast.error(response?.data?.createMaterial?.message)
    }
  }


  // const { data: singleOrderPurchaseData, isLoading } = useGetHook({
  //   queryKey: [`orderproduct`],
  //   url: `${APIS.ORDERS_ITEMS}`,
  //   params: {
  //     limit: 1,
  //     offset: 1,
  //     product: selectedValue
  //   }

  // });
  const { data: singleOrderPurchaseData, isLoading: singleScreenDataLoading, refetch } = useGetHook({
    queryKey: ['screen'],
    url: `${APIS.ORDERS_ITEMS}`,
    params: {
      limit: 1,
      offset: 1,
      product: selectedValue
    },
    enabled: false
  });
  const handleLazyFetch = async () => {
    const res = await refetch()
    return res.data
  }

  const { isPostLoading, mutateAsync, postData } = usePostHook({ queryKey: ["product"], navigateURL: null })
  const handleCreatePurchaseRawMaterials = async (values: any, selectedValue: string) => {
    const url = `${APIS.ORDERS}product-order/`
    const formData = {
      supplier: values?.supplier,
      product: selectedValue,
      cost_price: values?.cost_price,
      quantity: values?.quantity,
      discount: values?.discount,
      gst: values?.gst,
      discount_type: "percentage"
    }
    try {
      const res = await mutateAsync({ url, formData })
      console.log(res);
      setIsOpen(false)
    } catch (err) {
      console.log(err);
    }
  };



  return (
    <RawMaterialsComponent
      rawMaterialsData={rawMaterialsData?.products}
      handleRawMaterialsAdd={handleRawMaterialsAdd}
      isAddMaterialsOpen={isAddMaterialsOpen}
      setIsAddMaterialsOpen={setIsAddMaterialsOpen}
      supplierData={supplierData}
      search={search}
      setSearch={setSearch}
      itemsPerPage={itemsPerPage}
      setItemsPerPage={setItemsPerPage}
      setPage={setPage}
      page={page}
      loading={loading}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      handleCreatePurchaseRawMaterials={handleCreatePurchaseRawMaterials}
      setSelectedValue={setSelectedValue}
      selectedValue={selectedValue}
      handleLazyFetch={handleLazyFetch}

    />
  );
};

export default RawMaterials;
