import React from "react";
import loadable from "@loadable/component";
import { useParams } from "react-router-dom";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../contants/EndPoints";

const ViewOrdersComponent = loadable(() => import("../../components/Orders/ViewOrders"));

const ViewOrders = () => {
  const { id } = useParams()

  const { data: singleOrderData, isLoading } = useGetHook({
    queryKey: [`vieworder`],
    url: `${APIS.ORDERS}${id}`,
    params: {
    }
  });

  return (
    <>
      <ViewOrdersComponent
        singleOrderData={singleOrderData}
      />
    </>
  );
};

export default ViewOrders;
