import { gql } from "@apollo/client";

export const CREATE_MATERIAL = gql`
mutation($title:String!,$threshold:Decimal!,$price:Decimal!) {
  createMaterial(input: { title: $title, threshold: $threshold,price:$price}) {
    errors
    success
    message
  }
}
`;