import React from "react";
import loadable from "@loadable/component";

const OrderReceivedComponent = loadable(() => import("../../components/OrderReceived/OrderReceived"));

const OrderReceived = () => {
  return (
    <>
      <OrderReceivedComponent />
    </>
  );
};

export default OrderReceived;
