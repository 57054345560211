import React from "react";
import loadable from "@loadable/component";
import { useParams } from "react-router-dom";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";
import usePostHook from "../../customHooks/usePostHook";

const OrderReceivedDetailsComponent = loadable(() => import("../../components/OrderReceived/OrderReceivedDetails"));

const OrderReceivedDetails = () => {
  const { id } = useParams()
  const { data: singleOrderData, isLoading } = useGetHook({
    queryKey: [`vieworder`],
    url: `${APIS.ORDERS}${id}`,
    params: {
    }
  });

  const { isPostLoading: isValueLoading, mutateAsync: statusMutateAsync, postData: data } = usePostHook({ queryKey: ["order-received"], navigateURL: -1 })
  const handleStatusOrder = async (values: any, selectedValue: string) => {
    const url = `${APIS.ORDERS}${id}/response-confirmed/`

    try {
      const res = await statusMutateAsync({ url })
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <OrderReceivedDetailsComponent
        id={id}
        singleOrderData={singleOrderData}
        handleStatusOrder={handleStatusOrder}

      />
    </>
  );
};

export default OrderReceivedDetails;
